<!--
 * @Description: 复核通过
 * @Author: ChenXueLin
 * @Date: 2022-12-01 13:58:04
 * @LastEditTime: 2023-03-10 16:29:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[auditVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      title="复核通过"
      :visible.sync="auditVisible"
      :close-on-click-modal="false"
      width="500px"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="audit-dialog"
      :before-close="closeDialog"
      @open="openDialog"
      :modal-append-to-body="false"
    >
      <el-form
        label-width="100px"
        :model="form"
        :rules="formRule"
        :inline="true"
        ref="form"
      >
        <!-- :label-position="labelPosition" -->
        <el-form-item label="复核通过原因" prop="recheckPassReason">
          <el-input
            placeholder="请输入复核通过原因"
            v-model="form.recheckPassReason"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="质检扣费"
          prop="inspectionDeduction"
          :rules="[
            {
              required: false
            },
            {
              validator: positiveNumValid,
              message: '质检扣费必须为数字'
            }
          ]"
        >
          <el-input
            placeholder="请输入质检扣费"
            v-model="form.inspectionDeduction"
            :disabled="form.zeroSettle == 1"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="扣费原因" prop="deductionReason">
          <el-input placeholder="请输入扣费原因" v-model="form.deductionReason">
          </el-input>
        </el-form-item>
        <el-form-item label="是否0结算" prop="zeroSettle">
          <el-switch
            v-model="form.zeroSettle"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "auditDialog",
  components: {},
  data() {
    // 自定义验证规则
    var positiveNumValid = (rule, value, callback) => {
      if (/^([+-]?\d+)(\.\d+)?$/.test(value) || !value) {
        callback();
      } else {
        callback(new Error("请输入有效的数字"));
      }
    };
    return {
      positiveNumValid,
      dialogLoading: false,
      form: {
        inspectionDeduction: "",
        deductionReason: "",
        recheckPassReason: "",
        zeroSettle: 0
      },
      formRule: {
        // inspectionDeduction: [
        //   {
        //     required: true,
        //     message: "请输入质检扣费",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        // deductionReason: [
        //   {
        //     required: true,
        //     message: "请输入质检扣费原因",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        recheckPassReason: [
          {
            required: true,
            message: "请输入复核通过原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base],
  props: {
    auditVisible: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  watch: {},
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      this.form = {
        inspectionDeduction: "",
        deductionReason: "",
        recheckPassReason: ""
      };
    },
    //关闭弹框
    closeDialog() {
      this.dialogLoading = false;
      this.form = {
        inspectionDeduction: "",
        deductionReason: "",
        recheckPassReason: ""
      };
      this.$emit("update:auditVisible", false);
    },
    //修改0结算
    changeSwitch(val) {
      if (val == 1) {
        this.form.inspectionDeduction = "";
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit("confirm", this.form);
        }
      });
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.audit-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;
    .el-input {
      width: 300px;
    }
  }
}
</style>
