<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-12-01 13:58:04
 * @LastEditTime: 2023-03-10 16:29:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[rejectedVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      :title="title"
      :visible.sync="rejectedVisible"
      :close-on-click-modal="false"
      width="500px"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      :before-close="closeDialog"
      @open="openDialog"
      :modal-append-to-body="false"
    >
      <div class="dialog-title" v-if="needTip">{{ tipMessage }}</div>
      <el-form
        :model="form"
        label-width="80px"
        :inline="true"
        :rules="formRule"
        ref="form"
      >
        <el-form-item
          :label="title + '原因'"
          label-width="60"
          prop="rejectReason"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.rejectReason"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "rejectedDialog",
  components: {},
  data() {
    return {
      dialogLoading: false,
      form: {
        rejectReason: ""
      },
      formRule: {
        rejectReason: [
          {
            required: true,
            message: "请输入驳回原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base],
  props: {
    rejectedVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "驳回"
    },
    tipMessage: {
      type: String,
      default: "审核驳回，请确认!"
    },
    needTip: {
      type: Boolean,
      default: true
    }
  },

  computed: {},
  watch: {},
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      this.form.rejectReason = "";
    },
    //关闭弹框
    closeDialog() {
      this.dialogLoading = false;
      this.form = {
        rejectReason: ""
      };
      this.$parent.selColumnIds = [];
      this.$emit("update:rejectedVisible", false);
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dialogLoading = true;
          this.$emit("confirm", this.form.rejectReason);
        }
      });
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;

    .el-form {
      margin-top: 15px;
      .el-textarea {
        width: 350px;
      }
    }
  }
}
</style>
