import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  rejectTaskInspection,
  recheckTaskInspection,
  findDownList,
  submitTaskInspection,
  startQuality,
  getEngineer
} from "@/api";
const nowTime = new Date().getTime();
export default {
  data() {
    return {
      selColumns: [],
      searchForm: {
        itemId: "",
        customerName: "",
        engineerName: "",
        taskInspectionStatus: [],
        facilitatorName: "",
        sceneName: "",
        isNeedVisit: 1,
        taskInspectionType: "",
        inspectionFail: "",
        inspectionOperatorId: "",
        taskSource: [],
        zeroSettle: "",
        createTime: [nowTime - 2592000000], //创建日期
        createTimeStart: nowTime - 2592000000,
        createTimeEnd: "",
        inspectionTag: "",
        engineerrpId: "",
        pageIndex: 1,
        pageSize: 20
      },
      engineerList: [], //质检人下拉框
      taskSourceList: [],
      inspectionTagList: [],
      emusList: [
        {
          id: 0,
          label: "否"
        },
        {
          id: 1,
          label: "是"
        }
      ],
      inspectionFailList: [],
      addVisible: false,
      rejectedVisible: false,
      auditVisible: false,
      selColumnIds: [],
      workStatusList: [],
      taskTypeList: [],
      clickTaskInspectionId: "",
      needTip: false,
      recordVisible: false,
      total: 0,
      loading: false,
      tableData: [] // 表格数据
    };
  },
  computed: {},
  mixins: [listPage, base, listPageReszie],
  watch: {
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createTimeStart = val[0] || "";
        this.searchForm.createTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "taskSecondClass",
            "taskInspectionStatus",
            "inspectionFailReason",
            "inspectionTag",
            "taskSource"
          ]),
          getEngineer({})
        ];
        let [taskTypeRes, engineerRes] = await Promise.all(promiseList);
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //工单状态
        this.workStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskInspectionStatus"
        });
        //质检不通过原因
        this.inspectionFailList = this.getFreezeResponse(taskTypeRes, {
          path: "data.inspectionFailReason"
        });
        //质检标签
        this.inspectionTagList = this.getFreezeResponse(taskTypeRes, {
          path: "data.inspectionTag"
        });
        //工单来源
        this.taskSourceList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSource"
        });
        //获取工程师下拉框名称
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnIds = columns.map(item => {
        return item.taskInspectionId;
      });
      this.selColumns = columns;
    },
    // 获取页面操作权限
    getOperateList(row) {
      let operationIds = this.$route.meta.operationIds || [];
      // 页面按钮权限
      let operates = [
        {
          label: "工单详情",
          id: 1
        },
        {
          label: "复核通过",
          id: 2,
          disabled:
            (row.taskInspectionStatus != 1 && row.taskInspectionStatus != 4) ||
            !operationIds.includes(123)
        },
        {
          label: "驳回",
          id: 3,
          disabled: !row.isNeedVisit || !operationIds.includes(124)
        },
        {
          label: "质检记录",
          id: 4,
          disabled: !row.startInspectionTime || !operationIds.includes(304)
        },
        {
          label: "提交工单",
          id: 5,
          disabled: !operationIds.includes(305)
        }
        // {
        //   label: "工单详情",
        //   id: 6
        // }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.itemId,
            taskInspectionId: row.taskInspectionId,
            type: this.type
          }
        });
      }
      if (val.id == 2) {
        this.selColumnIds = [row.taskInspectionId];
        this.selColumns = [row];
        this.auditVisible = true;
      }
      if (val.id == 3) {
        this.selColumnIds = [row.taskInspectionId];
        this.selColumns = [row];
        this.rejectedVisible = true;
      }
      if (val.id == 4) {
        this.recordVisible = true;
        this.clickInspectionRecordId = row.inspectionRecordId;
        this.clickTaskInspectionId = row.taskInspectionId;
      }
      if (val.id == 5) {
        this.submitTaskInspectionReq(row.itemId);
      }
    },
    // 格式化数据
    formatList(data) {
      data.map(item => {
        item.responsibilityNameStr = item.recordInfoList
          .map(item => item.responsibilityName)
          .join(",");
        item.mainBodyNameStr = item.recordInfoList
          .map(item => item.mainBodyName)
          .join(",");
        item.failPointNameStr = item.recordInfoList
          .map(item => item.failPointName)
          .join(",");
      });
      return data;
    },
    //提交工单
    async submitTaskInspectionReq(itemId) {
      try {
        this.loading = true;
        let res = await submitTaskInspection({
          itemIdList: [itemId]
        });
        if (res.code == "OK") {
          this.$message.success("提交成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.clearSelect();
        this.loading = false;
      }
    },
    //开始质检
    startInspection() {
      if (!this.selColumnIds.length) {
        this.$message.warning("请先选择数据");
        return;
      }
      if (this.selColumns.some(ele => !ele.isNeedVisit)) {
        this.$message.warning("不需要上门的单，不能进行开始质检操作");
        return;
      }
      this.$confirm("确认要开始质检吗？", "确认开始质检", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.startQualityReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    async startQualityReq() {
      try {
        this.loading = true;
        let res = await startQuality({
          taskInspectionIdList: this.selColumnIds
        });
        if (res.code == "OK") {
          this.$message.success("开始质检操作成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.clearSelect();
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, this.exportDataApi);
    },
    handleAudit() {
      if (!this.selColumnIds.length) {
        this.$message.warning("请先选择数据");
        return;
      }
      this.auditVisible = true;
    },
    //复核通过
    async confirmAudit(form) {
      if (!form.zeroSettle && this.selColumns.some(ele => ele.zeroSettle)) {
        this.$message.warning("0结算的单不能改为非0结算");
        return;
      }
      this.$refs.auditDialog.dialogLoading = true;
      try {
        let res = await recheckTaskInspection({
          taskInspectionIdList: this.selColumnIds,
          ...form
        });
        if (res.code == "OK") {
          this.$refs.auditDialog.dialogLoading = false;
          this.$refs.auditDialog.closeDialog();
          this.$message.success("复核通过成功");
          this.queryList();
          this.clearSelect();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$refs.auditDialog.dialogLoading = false;
      }
    },
    handleTurnDown() {
      if (!this.selColumnIds.length) {
        this.$message.warning("请先选择数据");
        return;
      }
      if (this.selColumns.some(ele => !ele.isNeedVisit)) {
        this.$message.warning("不需要上门的单，不能进行驳回操作");
        return;
      }
      this.rejectedVisible = true;
    },
    //驳回请求
    async confirmTurnDown(rejectReason) {
      try {
        let res = await rejectTaskInspection({
          taskInspectionIdList: this.selColumnIds,
          rejectReason
        });
        if (res.code == "OK") {
          this.$refs.rejectedDialog.dialogLoading = false;
          this.$refs.rejectedDialog.closeDialog();
          this.$message.success("驳回成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.clearSelect();
        this.$refs.rejectedDialog.dialogLoading = false;
      }
    },
    clearSelect() {
      this.selColumnIds = [];
      this.selColumns = [];
      this.$refs.elTable.clearSelection();
    },
    confirmRecord() {
      this.queryList();
    }
  }
};
