<!--
 * @Description:安装质检
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemId">
              <el-input
                v-model="searchForm.itemId"
                placeholder="工单ID"
                title="工单ID"
                @input="
                  searchForm.itemId = searchForm.itemId.replace(/[^0-9-]+/, '')
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="customerName">
              <el-input
                v-model="searchForm.customerName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerName">
              <el-input
                v-model="searchForm.engineerName"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="inspectionOperatorId">
              <all-user-search
                v-model="searchForm.inspectionOperatorId"
                clear
                placeholder="质检人"
                title="质检人"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
                clearable
              >
              </all-user-search>
            </el-form-item>
            <el-form-item prop="taskInspectionStatus">
              <e6-vr-select
                v-model="searchForm.taskInspectionStatus"
                :data="workStatusList"
                placeholder="工单状态"
                title="工单状态"
                clearable
                virtual
                multiple
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSource">
              <e6-vr-select
                v-model="searchForm.taskSource"
                :data="taskSourceList"
                placeholder="工单来源"
                title="工单来源"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="facilitatorName">
              <el-input
                v-model="searchForm.facilitatorName"
                placeholder="服务商名称"
                title="服务商名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="isNeedVisit">
              <e6-vr-select
                v-model="searchForm.isNeedVisit"
                :data="emusList"
                placeholder="是否需要上门"
                title="工单类型"
                clearable
                virtual
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="taskInspectionType">
              <e6-vr-select
                v-model="searchForm.taskInspectionType"
                :data="taskTypeList"
                placeholder="工单类型"
                title="工单类型"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="inspectionFail">
              <e6-vr-select
                v-model="searchForm.inspectionFail"
                :data="inspectionFailList"
                placeholder="质检不通过原因"
                title="质检不通过原因"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="zeroSettle">
              <e6-vr-select
                v-model="searchForm.zeroSettle"
                :data="emusList"
                placeholder="是否0结算"
                title="是否0结算"
                clearable
                virtual
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="inspectionTag">
              <e6-vr-select
                v-model="searchForm.inspectionTag"
                :data="inspectionTagList"
                placeholder="质检标签"
                title="质检标签"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="质检单创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="质检单创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="质检单创建时间（始）"
                end-placeholder="质检单创建时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button type="primary" @click="startInspection" v-has="122"
            >开始质检</el-button
          >
          <el-button type="primary" @click="handleAudit" v-has="123"
            >复核通过</el-button
          >
          <el-button type="primary" @click="handleTurnDown" v-has="124"
            >驳回</el-button
          >
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="handleExport"
            v-has="105"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in installColumnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div v-if="column.fieldName === 'detectionFinishTimeList'">
                <div
                  v-for="(item, index) in row.detectionFinishTimeList"
                  :key="index"
                >
                  <div :data-title="item" class="content-text">
                    {{ item.materialName }} :{{ item.detectionFinishTime }}
                  </div>
                </div>
              </div>
              <div v-else-if="column.fieldName === 'serverOpenTimeList'">
                <div
                  v-for="(item, index) in row.serverOpenTimeList"
                  :key="index"
                >
                  <div :data-title="item" class="content-text">
                    {{ item.materialName }} :{{ item.detectionFinishTime }}
                  </div>
                </div>
              </div>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="160"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <rejected-dialog
      :rejectedVisible.sync="rejectedVisible"
      :needTip="needTip"
      @confirm="confirmTurnDown"
      ref="rejectedDialog"
    ></rejected-dialog>
    <audit-dialog
      :auditVisible.sync="auditVisible"
      @confirm="confirmAudit"
      ref="auditDialog"
    ></audit-dialog>
    <!-- 质检记录 -->
    <inspection-record-dialog
      :recordVisible.sync="recordVisible"
      ref="inspectionRecordDialog"
      type="installQualityInspection"
      @confirm="confirmRecord"
      :taskInspectionId="clickTaskInspectionId"
    ></inspection-record-dialog>
  </div>
</template>

<script>
import mixin from "./mixin";
import allUserSearch from "@/components/allUserSearch";
import {
  getInstallTaskInspectionPage,
  exportInstallTaskInspection
} from "@/api";
import RejectedDialog from "@/components/rejectedDialog.vue";
import AuditDialog from "./auditDialog.vue";
import inspectionRecordDialog from "./inspectionRecordDialog";
import { installColumnData } from "./columnData";
export default {
  name: "installQualityInspection",
  data() {
    return {
      type: "installQualityInspection",
      installColumnData,
      queryListAPI: getInstallTaskInspectionPage,
      exportDataApi: exportInstallTaskInspection
    };
  },
  mixins: [mixin],
  components: {
    RejectedDialog,
    AuditDialog,
    inspectionRecordDialog,
    allUserSearch
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss" scoped></style>
